.section-label {
  display: flex;
  vertical-align: middle;
}

.activity-header {
  height: 65px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.activity-container {
  padding: 0 20px;
  //height: calc(100vh - 190px);
  overflow-y: auto;
  //border-top: 1px solid #DEDEDE;
}

/* Card Styling */

.activity-title {
  padding: 0 10px;
  height: 24px;
  background: #E2E2E2;
  border-radius: 5px 5px 0 0;
  line-height: 24px;
}

.activity-title i {
  margin-right: 5px;
  //color: #512da8;
  color: #5a2ed1;
}

.activity-details__button--download {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #FFFFFF;
  border: 1px solid;
  //color: #D700A9;
  line-height: 20px;
  transition: all 0.3s ease 0s;
  font-family: 'Fira Sans', sans-serif;
}

.activity-details__button--download:hover {
  cursor: pointer;
  //background: #D700A9;
  //color: #FFFFFF;
}

.activity-content__link {
  color: #5a2ed1;
  font-weight: bold;
}

.activity-content {
  padding: 15px;
  background: #FCFCFC;
}

.activity-details {
  font-size: 12px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.activity-details__button--download.left {
  margin-right: 7px;
}

.activity-details__button--download.right {
  margin-left: 7px;
}

.alert-counter {
  font-size: 10pt;
  background: #311B92;
  border-radius: 50%;
  color: white;
  height: 18px;
  width: 18px;
  text-align: center;
  display: inline-block;
}

/* Expandable Activity Card sets */

$transactionCardHeight: 100px;
$accountCardHeight: 180px;
$reportingAlertHeight: 135px;

.account-card,
.transaction-card,
.reporting-alert,
.sidebar-card {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  background: #FCFCFC;
  white-space: normal !important               ;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10pt !important;
  line-height: 21px !important;

  -webkit-transition: margin-bottom .5s;
  -moz-transition: margin-bottom .5s;
  -o-transition: margin-bottom .5s;
  transition: margin-bottom .5s;
}

.activity-card {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;

  white-space: normal !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10pt !important;
  line-height: 21px !important;
}

.transaction-card {
  height: $transactionCardHeight;
  margin-bottom: $transactionCardHeight * -1;
}

.account-card {
  height: $accountCardHeight;
  margin-bottom: $accountCardHeight * -1;
}

.reporting-alert {
  height: $reportingAlertHeight;
  margin-bottom: $reportingAlertHeight * -1;
}

.account-card:not(:first-child), .transaction-card:not(:first-child), .reporting-alert:not(:first-child)  {
  position: relative;
  z-index: 999;
  display: none;
}

.account-card:nth-child(2), .transaction-card:nth-child(2), .reporting-alert:nth-child(2)  {
  display: block;
  z-index: 999;
}

.account-card:nth-child(3), .transaction-card:nth-child(3), .reporting-alert:nth-child(3)   {
  display: block;
  z-index: 998;
  margin-bottom: 0;
}

.account-card:last-child, .transaction-card:last-child, .reporting-alert:last-child {
  margin-bottom: 0;
}

.expanded {
  margin-top: 10px;
  margin-bottom: 0;
}

.transaction-card.expanded:not(:first-child), .account-card.expanded:not(:first-child), .reporting-alert.expanded:not(:first-child)  {
  margin-top: 10px;
  margin-bottom: 0;
  display: block;
}

/* Button for expanding */

.expand-button {
  width: auto;
  padding: 0 5px 0 5px;
  font-size: 10pt;
  height: 20px;
  background: #E4E4E4;
  border-radius: 100px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  font-size: 16px;
}

.loader.error {
  padding: 50px;
  color: #767676;
  line-height: 26px;
}
