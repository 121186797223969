// Toggle Header for use in a material card

.toggle-header.mat-card-header {
  flex: none;
  display: inline-block;
}


.mat-toggle-two-labels {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 200px;
}


.mat-toggle-two-labels label {
  font-size: 14px;
  margin-bottom: 0;
  vertical-align: middle;
  line-height: .6em;
  font-weight: normal;
}

.mat-toggle-two-labels label.active {
  font-weight: bold;
}

.mat-toggle-two-labels mat-slide-toggle {
  margin-left: 5px;
  margin-right: 5px;
}

.card-header__toggle-container {
  display: flex;
  align-items: center;
  color: #636363;
}

.option {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 12px;
  transition: all 0.3s ease 0s;
}

.option:hover {
  cursor: pointer;
}

.active {
  background: #474747;
  color: #FFFFFF;
  font-weight: 900;
}
