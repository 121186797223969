@mixin atlas-tabs($theme) {
  .tab-container {
    margin-top: 20px;
    font-size: 15px;
    padding: 0;
    list-style: none;
    background: #EDEDED;
    display: inline-block;
    border-radius: 50px;
    position: relative;
  }

  $primary: map-get($theme, primary);

  .selector {
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 50px;
    transition-duration: 0.6s;
    background: map-get($primary, 500);
    padding: 0 20px;
  }
}
