@mixin atlas-form-controls($theme) {
  $primary: map-get($theme, primary);

  // Checkbox
  .mat-checkbox:not(.mat-checkbox-disabled) {
    &.mat-accent {
      .mat-checkbox-ripple {
        .mat-ripple-element {
          background-color: map-get($primary, 400);
        }
      }
    }

    .mat-checkbox-inner-container {
      width: 18px;
      height: 18px;
    }
  }

  .mat-checkbox-checked{
    &.mat-accent .mat-checkbox-background {
      background-color: map-get($primary, 400);
    }
  }

  // Input
  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 16px 0 !important;
      border-width: 1px !important;
    }

    .mat-form-field-outline-thick {
      color: map-get($primary, 400);
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end{
        border-width: 1px!important;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        background-color: #EDEDED;
        border-color: #EDEDED;
      }
    }

    .mat-select-arrow-wrapper {
      transform: none!important;
    }

    .mat-form-field-suffix {
      top: 0.4em!important;
    }
  }

  .mat-form-field-infix {
    border: none;
  }

  // Selectbox
  .mat-option .atlas-image-option {
    position: relative;
    bottom: -8px;
    border-radius: 50%;
    width: 25px;
  }

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    height: 2.1em!important;
    width: 2.1em!important;
  }
}
