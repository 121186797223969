$primary-purple: rgb(90, 46, 209);

.content-wrap {
  padding: 30px;
}

.header-spacer {
  height: 160px;
}

.mat-app-navigation-toolbar {
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.secondary-navigation-toolbar {
  border-radius: 4px;
  margin-bottom: 30px !important;
  background: #FFFFFF !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07),
  0 2px 4px rgba(0,0,0,0.07),
  0 4px 8px rgba(0,0,0,0.07) !important;

}

#client-toolbar {
  position: sticky;
  //top: 30px;
  z-index: 9999;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.card-loading-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100px;
}

.dashboard-tile {
  display: flex;
  min-height: 100%;
  box-sizing: border-box;
}

.no-data {
  display: flex;
  height: 100px;
  align-items: center;
  text-align: center;
}

.data-grid-actions {
  float: right;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.detail-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.02em  !important;
  margin-right: 10px;
  color: #7c7c7c !important;
  //font-family: 'Open Sans', sans-serif;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.help-block {
  font-size: 75%;
  color: rgba(0, 0, 0, 0.6);
}

.span-flex {
  display: flex;
  flex: 1;
}

.info-icon {
  font-size: 18px;
  color: #7C7C7C;
  margin-left: 7px;
}

.web-report-info-icon {
  font-size: 14px;
  color: #474747;
}

.tooltip {
  font-size: 16px;
  line-height: 1.5;
  max-width: 600px !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //align-items: center;
  margin-bottom: 20px;
}

.info-symbol {
  margin-left: 8px;
  color: #767676;
  font-size: 20px;
}

.searchbar-icon {
  color: #767676;
  margin-right: 10px;
}

.subreport-header__title {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin: 0;
    font-size: 24px;
  }

  .subtitle {
    color: rgba(0, 0, 0, 0.54)
  }
}

.card-header__title {
  display: flex;
  align-items: baseline;

  h2 {
    margin: 0;
    font-size: 24px;
  }
}

.card-header__toggle {
  display: flex;
  align-items: center;
}

.toggle-element {
  margin: 0 10px;
}

.bottom-right-margin {
  margin-right: 5px;
  margin-bottom: 5px;
}

.subreport-max-height {
  max-height: 500px;
}

.negative-number {
  color: red;
}

.small {
  color: #636363;
  font-size: 14px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.handle {
  color: #636363;
  margin-left: 5px;
}

.handle:hover {
  cursor: grab;
}

.handle:active {
  cursor: grabbing;
}

.card-options {
  display: flex;
  align-items: center;
}

.no-hover-effect {
  background-color: transparent !important;
}

.mat-drawer-container {
  position: unset !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.search-bar {
  width: 250px;
  height: 40px;
  border-radius: 4px;
  //box-shadow: 0 3px 0 rgb(103, 58, 183);
  background: #F3F4F7;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.search-bar input {
  border: none;
  font-size: 14px;
  width: 100%;
  font-family: 'Fira Sans', sans-serif;
  background: #F3F4F7;
}

.search-bar input:focus {
  outline: none;
}

.flex-row-align-center {
  display: flex;
  align-items: center;
}

.wealthbox-mat-tab-group {
  height: calc(100vh - 190px);
}

.wealthbox-mat-tab-group::-webkit-scrollbar,
.mat-tab-body-content::-webkit-scrollbar,
.wealthbox-mat-tab-group .dx-scrollable-container::-webkit-scrollbar,
.activity-container::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.wealthbox-mat-tab-group,
.mat-tab-body-content,
.wealthbox-mat-tab-group .dx-scrollable-container,
.activity-container {
  scrollbar-width: none;
}

.firmwide-reporting-form {
  .mat-form-field-wrapper {
    margin-right: 16px;
  }

  .mat-form-field-flex {
    padding: 5px 15px;
    border-radius: 4px;
    background: #F3F4F7;
    align-items: center;
  }
}

.card-max-height {
  //to prevent unecessary scrolling outside of card
  //65px navbar + 30px top/bottom margins
  max-height: calc(100vh - 125px);
}

.recent-activity-card {
  border: 1px solid #DEDEDE;
  background: #ffffff;
  border-left: 5px solid;
  border-radius: 6px;
  padding: 16px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );

  a {
    font-weight: bold;
  }

  display: flex;
  align-items: center;

  .content-container {
    flex: 1;
  }

  .content-header {
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 6px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .align-items-center {
    display: flex;
    align-items: center;
  }

  .content {
    font-size: 13px;
    color: #636363;
    white-space: normal;
    width: 100%;
    line-height: 20px;
  }

  .date {
    color: #636363;
    font-size: 12px;
  }

  i.actions {
    margin-left: 16px;
    margin-right: 0;
    font-size: 12px;
  }

  i {
    //color: #D700A9;
    margin-right: 20px;
    font-size: 24px;
  }

  //&.pdf i {
  //  color: #0064FB;
  //}
  //
  //&.security i {
  //  color: #FF7C53;
  //}
  //
  //&.account i {
  //  color: #00BC97;
  //}
}

.accordion-container {
  .label {
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    padding: 2px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

  .label:hover {
    cursor: pointer;
    font-weight: bold;
  }

  .input {
    display: none;
  }

  .accordion-content {
    overflow: hidden;
    height: 0;
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
  }

  .input:checked ~ .accordion-content {
    overflow: auto;
    height: 100px;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 10px;
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
  }
}

.mat-drawer-backdrop {
  position: fixed !important;
}

//for mat dialog action buttons for bulk assignment modals in client section
.actions-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
}

.notes {
  border: 2px solid #BDBDBD;
  border-radius: 4px;
  padding: 20px;
  position:relative;
  margin: 24px 20px;

  .notes-label {
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    position: absolute;
    top: -14px;
    padding: 0 10px;
    color: #BDBDBD;
    background: #ffffff;
  }

  .settings-link {
    text-decoration: underline;
    display: contents;

    &:hover {
      cursor: pointer;
    }
  }
}

.mat-option.reporting-account-option {
  line-height: 24px !important;
  margin-bottom: 16px !important;
}

.mat-form-field-label {
  padding-top: 7px;
}
