@mixin atlas-input($theme) {
  $warn: map-get($theme, warn);

  .atlas-input {
    display: flex;
    align-items: center;
    .atlas-input-element {
      font: inherit;
      background: 0 0;
      color: currentColor;
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      vertical-align: bottom;
      text-align: inherit;
    }

    .atlas-input-suffix {
      padding-left: 5px;
      display: none;
      .icon-gray {
        color: #A1A1A1;
      }

      .icon-red {
        color: map-get($warn, 400);
      }
    }

    &.has-suffix {
      .atlas-input-suffix {
        display: block;
      }
    }
  }
}
