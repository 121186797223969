@mixin atlas-table($theme) {
  $primary: map-get($theme, primary);
  // Theming for an expandable row
  .expandable-table {
    tr.example-detail-row {
      height: 0;
    }

    tr.expandable-row:hover {
      background: map-get($primary, 100);
    }

    .expandable-row td {
      border-bottom-width: 0;
    }

    .active {
      background: map-get($primary, 100);
    }

    .example-element-row td {
      border-bottom-width: 0;
    }

    .detail-row {
      overflow: hidden;
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
