body {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

$themes: (
  bridge: (
    colorPrimary1: #311B92,
    colorPrimary2: #512DA8,
    colorPrimary3: #673AB7,
    colorPrimary4: #9575CD,
    colorPrimary5: #D1C4E9,
    activeColor: #221267,
    colorSecondary1: #FFEA00,
    colorSecondary2: #FFFF00,
    colorSecondary3: #FFFF8D,
    colorNeutral1: #EDEDED,
    colorNeutral2: #F5F5F5,
    colorNeutral3: #FAFAFA,
    invalidColor: #B10A0A,
    validColor: #28742B
  )
);

//$atlas-background: #F4F6FB;
$atlas-background: #aba9ff;
$atlas-icon-dark: #838383;
