@import "../mixins/themify";

a:link {
  @include themify($themes) {
    color: themed('colorPrimary1')
  }
}

a:visited {
  @include themify($themes) {
    color: themed('activeColor')
  }
}

a:hover {
  @include themify($themes) {
    color: themed('colorPrimary2')
  }
}

a:active {
  @include themify($themes) {
    color: themed('activeColor')
  }
}

a:disabled {
  @include themify($themes) {
    color: themed('colorPrimary5')
  }
}
